import React from 'react'

const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')
const InfiniteScrollLayout = Loader.loadBaseComponent('ReactVirtualized', 'InfiniteScrollLayout')

const cards = [
  {
    type: 'KeyPointCard',
    component: Loader.loadBusinessComponent('Card', 'KeyPointCard')
  },
  {
    type: 'ForeignCard',
    component: Loader.loadBusinessComponent('Card', 'ForeignCard')
  },
  {
    type: 'PersonTrackCard',
    component: Loader.loadBusinessComponent('Card', 'PersonTrackCard')
  }
]

class HistoryAlarmList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      handleVisible: false,
      type: 1,
      modalItem: {},
      hasLoadMore: true
    }
    this.infinitRef = React.createRef()
  }

  handleChangeYN = (item, type, e) => {
    Utils.stopPropagation(e)
    this.setState({
      modalItem: item,
      handleVisible: true,
      type
    })
  }

  handleCancel = () => {
    this.setState({
      modalItem: {},
      handleVisible: false,
      type: 1
    })
  }

  handleOk = () => {
    let { type, modalItem } = this.state
    this.props.handle(modalItem, type).then(this.handleCancel)
  }

  handlePageJump = (id, libType) => {
    const { handlePageJump } = this.props
    handlePageJump && handlePageJump(id, libType)
  }

  loadMore = () => {
    let { searchData, onTypeChange, total } = this.props
    this.setState(
      {
        hasLoadMore: false
      },
      () => {
        if (searchData.offset > total - searchData.limit) {
          return
        }
        searchData.offset += searchData.limit
        onTypeChange &&
          onTypeChange(searchData, true).then(length => {
            if (length === searchData.limit) {
              this.setState({ hasLoadMore: true })
            }
          })
      }
    )
  }

  render() {
    let { list, libType, infinitKey, cardType, pdWidth, itemWidth, itemHeight } = this.props
    let { handleVisible, type, hasLoadMore } = this.state
    let Card = cards.filter(v => v.type === cardType)[0].component
    const libLabel = libType !== 3 ? '告警' : '提醒'
    return (
      <>
        <InfiniteScrollLayout
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          key={infinitKey}
          pdWidth={pdWidth}
          data={list}
          ref={this.infinitRef}
          hasBackTop={true}
          loadMore={this.loadMore}
          hasLoadMore={hasLoadMore}
          renderItem={(item, index) => (
            <div key={item.id}>
              <Card
                data={item}
                libType={libType}
                type={1}
                height={itemHeight}
                score={libType === 4 ? item.score : null}
                handleJumPage={this.handlePageJump}
                handleChangeYN={this.handleChangeYN}
              />
            </div>
          )}
        />
        <ConfirmComponent
          title={type == 1 ? `有效${libLabel}确认` : `无效${libLabel}确认`}
          visible={handleVisible}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
          width={320}
          icon={type == 1 ? 'icon-S_Photo_ThumbEffective' : 'icon-S_Photo_ThumbInvalid'}
          children={
            <div>
              点击“确定”将其标注为{type == 1 ? '有' : '无'}效{libLabel}？
            </div>
          }
        />
      </>
    )
  }
}

export default HistoryAlarmList
