import React from 'react'
import { message } from 'antd'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import AlarmList from '../alarmList'
import TaskList from '../taskList'
import moment from 'moment'
import './index.less'

const MonitorNavigation = Loader.loadBusinessComponent('MonitorNavigation')
const NoData = Loader.loadBaseComponent('NoData')
const Loading = Loader.Loading

const LittlePagtion = Loader.loadBusinessComponent('BaseLibComponents', 'LittlePagtion')
const SearchInput = Loader.loadBaseComponent('SearchInput')

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('tab')
@observer
class MonitorHistoryAlarmContainer extends React.Component {
  constructor(props) {
    super(props)
    const { searchDataInit, searchList, libType } = props
    this.searchList = Shared.searchList(searchList)
    this.state = {
      libType,
      loading: false,
      searchData: searchDataInit,
      infinitKey: Math.random(),
      total: 0,
      dataList: {
        list: []
      } // 告警列表数据
    }
    this.listRef = React.createRef()
    this.taskListRef = React.createRef()
    this.searchOn = false
  }

  componentWillMount() {
    this.initUrlOptions()
    SocketEmitter.on(SocketEmitter.eventName.resolveAlarm, this.updateCard)
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.resolveAlarm, this.updateCard)
  }

  updateCard = data => {
    let { dataList } = this.state
    let item = dataList.list.find(v => v.id == data.id)
    if (item) {
      item.isEffective = data.isEffective
      item.isHandle = 1
      this.setState(
        {
          dataList
        },
        () => {
          this.listRef.current.infinitRef.current.forceUpdateGrid()
        }
      )
    }
  }

  /**
   * @desc 根据url获取查询条件
   */
  initUrlOptions = () => {
    const { location, searchDataInit } = this.props
    const { id } = Utils.queryFormat(location.search)
    if (id) {
      LM_DB.get('parameter', id).then(result => {
        this.setState({ searchData: { ...searchDataInit, ...result.data } })
      })
    }
  }

  /**
   * @desc 搜索条件存入indexDB
   */
  saveDbSearchData = options => {
    const id = Utils.uuid()
    const { moduleName, tab } = this.props
    LM_DB.add('parameter', {
      id,
      data: options
    }).then(() => {
      tab.goPage({
        moduleName,
        isUpdate: true,
        data: { id },
        action: 'replace'
      })
    })
  }

  // 布控详情跳转
  handlePageJump = (id, libType) => {
    const { tab, detailModuleName } = this.props
    const { searchData, dataList } = this.state
    for (let i in searchData) {
      if (!searchData[i]) {
        delete searchData[i]
      }
    }
    const list = dataList.list
    const findIndex = list.findIndex(v => v.id === id)
    if (findIndex > -1) {
      const number = Math.floor(findIndex / searchData.limit)
      searchData.offset = number * searchData.limit
    }

    tab.openDetailPage({
      moduleName: detailModuleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add('parameter', {
          id: id.toString(),
          libType,
          list,
          isRealAlarm: false,
          searchData
        })
      }
    })
  }

  // 搜索条件选择
  searchDataChange = (options, loadMore = false) => {
    let { searchData, libType, dataList, infinitKey } = this.state
    let params = Object.assign({}, searchData, options)
    if (libType === 3) {
      if (!params.timeType) {
        params.endTime = moment().valueOf()
        params.startTime = moment(
          moment()
            .subtract(29, 'd')
            .format('YYYY-MM-DD 00:00:00')
        ).valueOf()
      }
    }
    // 新增搜索前判断是否存在id，如果不存在，不搜索
    let taskIds = params.taskIds || []
    if (!taskIds.length) {
      this.setState({ searchData: params })
      return Promise.resolve(true)
    }
    this.setState({ loading: true })
    return Service.alarmResult
      .queryAlarmResults(params, libType)
      .then(res => {
        if (loadMore) {
          dataList.list = dataList.list.concat(res.data.list)
        } else {
          dataList = res.data
          infinitKey = Math.random()
          const { limit, offset, sort, ...options } = params
          Service.alarmResult.countAlarmResults(options).then(res => {
            this.setState({ total: res.data.total })
          })
        }
        this.setState({
          loading: false,
          searchData: params,
          dataList,
          infinitKey
        })
        this.searchOn && this.saveDbSearchData(params)
        this.searchOn = true
        let length = res.data.list.length
        return length
      })
      .catch(err => {
        message.error(err.Error)
        this.setState({ loading: false })
      })
  }

  // 刷新按钮
  onChange = () => {
    let { taskIds = [] } = this.state.searchData
    if (taskIds.length) {
      // 同时更新任务列表和告警列表
      this.taskListRef.current.getTaskList().then(result => {
        this.taskListRef.current.getAlarmUnResolveNum(result)
      })
      this.searchDataChange({ offset: 0 })
    }
  }

  // 修改store 参数
  setStoreSeacrhData = value => {
    const { searchData } = this.state
    let params = Object.assign({}, searchData, value)
    this.setState({ searchData: params })
  }

  // 关键字搜索
  searchInputChange = value => {
    this.searchDataChange({ monitorPersonKeywords: value, offset: 0 })
  }

  /**处理警情 */
  handle = (item, isEffective, operationDetail = undefined) => {
    const { libType } = this.state
    return Service.alarmResult
      .handleAlarmResult(
        {
          id: item.id,
          isEffective,
          operationDetail
        },
        {
          libType
        }
      )
      .then(res => {
        message.info('设置' + (libType == 3 ? '' : '告警状态') + '成功')
        //更新警情
        let { list } = this.state.dataList
        let listItem = list.find(v => v.id === item.id)
        if (listItem) {
          listItem.isHandle = 1
          listItem.isEffective = isEffective
        }
        this.setState(
          {
            dataList: {
              list
            }
          },
          () => {
            this.listRef.current.infinitRef.current.forceUpdateGrid()
          }
        )
        // 更新布控告警列表的告警数量
        return true
      })
  }

  /**新增方法 根据获取的布控任务id重新获取数据，刷新列表 获得id后，之前所有的查询条件重置*/
  getTaskId = taskIds => {
    let { searchData } = this.state
    // let {searchDataInit} = this.props
    /** 使用初始搜索条件 */
    let searchDataObj = Object.assign({}, searchData, { taskIds, offset: 0 })
    if (!taskIds.length) {
      this.setState({
        loading: false,
        total: 0,
        dataList: {
          list: []
        }
      })
      this.setStoreSeacrhData(searchDataObj)
      return Promise.resolve(true)
    }
    return this.searchDataChange(searchDataObj)
  }

  render() {
    const { dataList, loading, searchData, infinitKey, libType, total } = this.state
    const { className = '', cardType, cardStyle, showSearchInput, taskType, moduleName } = this.props
    const { list } = dataList
    let alarmHandlePriv = true

    return (
      <MonitorNavigation libType={libType} currentMenu={moduleName}>
        <div className={`history-alarm-wrapper ${className}`}>
          <div className="alarm-task">
            <TaskList ref={this.taskListRef} onTaskChange={this.getTaskId} taskTypes={[taskType]} isToFather={true} />
          </div>
          <div className="alarm-container">
            <div className="alarm-container-header">
              <div className="left">
                {this.searchList.map(v => (
                  <v.component key={v.type} libType={libType} searchData={searchData} onTypeChange={this.searchDataChange} />
                ))}
              </div>
              <div className="right">
                {showSearchInput && <SearchInput onChange={this.searchInputChange} placeholder="请输入姓名或身份证号搜索" />}
              </div>
            </div>
            <LittlePagtion searchData={searchData} total={total || 0} loading={loading} onChange={this.onChange} />
            <div className="alarm-history-box-out">
              <React.Fragment>
                {list && list.length ? (
                  <div
                    className={`
                        task-list 
                        ${!list.length ? '' : 'no-data'}
                        ${alarmHandlePriv ? '' : 'no-handle'}
                      `}
                  >
                    <AlarmList
                      cardType={cardType}
                      {...cardStyle}
                      // itemWidth={340}
                      // itemHeight={350}
                      // pdWidth={8}
                      list={list}
                      total={total}
                      loading={loading}
                      infinitKey={infinitKey}
                      searchData={searchData}
                      handle={this.handle}
                      ref={this.listRef}
                      onTypeChange={this.searchDataChange}
                      libType={libType}
                      handlePageJump={this.handlePageJump}
                    />
                  </div>
                ) : loading ? (
                  <Loading loading={loading} />
                ) : (
                  <NoData imgType={2} title={'告警数据'} />
                )}
              </React.Fragment>
            </div>
          </div>
        </div>
      </MonitorNavigation>
    )
  }
}

export default MonitorHistoryAlarmContainer
