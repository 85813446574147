import TaskListFilter from './TaskListFilter';
import TaskList from './taskList';
import AlarmList from './alarmList';
import RealAlarmList from './realAlarmList';
import AlarmDetailHeader from './alarmDetailHeader';
import HistoryAlarmContainer from './HistoryAlarmContainer';

const monitorHistoryAlarm = {
  TaskListFilter,
  TaskList,
  AlarmList,
  RealAlarmList,
  AlarmDetailHeader,
  HistoryAlarmContainer
};

export default monitorHistoryAlarm;
