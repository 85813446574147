import React from "react";
import { Progress, Input, Button } from "antd";
import "./index.less";
const { TextArea } = Input;
const ImageView = Loader.loadBusinessComponent("ImageView");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
const IconFont = Loader.loadBaseComponent("IconFont");

class AlarmDetailHeader extends React.Component {
  render() {
    let { data = {}, handleText, operationDetail, handleOpenModal, rightCenterP, saveText, score, isEvent, handleAuthName='keyPersonnelHandle' } = this.props;
    return (
      <>
        <div className="alarm-detail-header">
          <div className="alarm-detail-left">
            <div className="left-img">
              {data.imageUrl && (
                <div className="detailed-Cloth">
                  <div className="cloth-img">
                    <ImageView src={data.imageUrl} type="multiple" hasErrorImageStyle={false} />
                  </div>
                  <p className="cloth-p">布控人脸</p>
                </div>
              )}
              {data.faceUrl && (
                <div className="detailed-Cloth">
                  <div className="cloth-img">
                    <ImageView src={data.faceUrl} type="multiple" hasErrorImageStyle={false} />
                  </div>
                  <p className="cloth-p">{isEvent ? "抓拍人体" : "抓拍人脸"}</p>
                </div>
              )}
              {data.isHandle == 1 && <div className={`detailed_rotate ${data.isEffective == 0 ? "rotate_no" : "rotate_yes"} `} />}
            </div>
            {score && (
              <div className="header-progress alarm-score-progress">
                <span className="progress-span">相似度</span>
                <Progress percent={data.score && Math.floor(data.score)} status="active" strokeWidth={2} />
              </div>
            )}
          </div>
          <div className="alarm-detail-right">
            <div className="right-center">{rightCenterP}</div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="buttons">
            {data.isHandle == 0 && (
              <AuthComponent actionName={handleAuthName}>
                <div className="detail_header_button">
                  <Button className="header_btn" onClick={() => handleOpenModal(0)}>
                    <IconFont type={"icon-S_Photo_ThumbInvalid"} theme="outlined" />
                    无效
                  </Button>
                  <Button className="header_btn" onClick={() => handleOpenModal(1)}>
                    <IconFont type={"icon-S_Photo_ThumbEffective"} theme="outlined" />
                    有效
                  </Button>
                </div>
              </AuthComponent>
            )}
            <AuthComponent actionName={handleAuthName}>
              <Button type="primary" className="save" onClick={() => saveText()}>
                保存备注
              </Button>
            </AuthComponent>
          </div>
          <div className="detail-text">
            <TextArea className="detail-input" placeholder={"请输入警情备注，最大长度不超过200"} maxLength={201} rows={4} onChange={handleText} value={operationDetail} />
          </div>
        </div>
      </>
    );
  }
}

export default AlarmDetailHeader;
