/*
 * @Desc: 历史告警布控任务列表
 * @Date: 2019-04-17 13:38:17
 * @Last Modified by: welson
 * @Last Modified time: 2019-08-13 17:22:45
 */

import React from 'react'
import { Popover, List, Checkbox, Spin, message } from 'antd'
import { observer } from 'mobx-react'

import './index.less'
import moment from 'moment'

const NoData = Loader.loadBaseComponent('NoData')
const SearchInput = Loader.loadBaseComponent('SearchInput')
const IconFont = Loader.loadBaseComponent('IconFont')
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')
const TaskListFilter = Loader.loadBusinessComponent('MonitorHistoryAlarm', 'TaskListFilter')

const Loading = Loader.Loading

const statusMap = [
  {
    value: -1,
    statusLabel: '全部'
  },
  {
    value: 0,
    statusLabel: '已暂停',
    statusCls: 'be-paused'
  },
  {
    value: 1,
    statusLabel: '运行中',
    statusCls: 'be-running'
  },
  {
    value: 2,
    statusLabel: '未开始',
    statusCls: 'out-of-date'
  },
  {
    value: 3,
    statusLabel: '已过期',
    statusCls: 'out-of-date'
  },
  {
    value: 4,
    statusLabel: '已删除',
    statusCls: 'be-deleted'
  }
]

/**
 * 说明
 * queryType：任务来源: 1全部任务 2自己创建的任务 3指派任务 4本地任务, 不传默认为 1
 * taskTypes: 布控任务类型: '101501'-重点人员 '101502'-外来人员 '101503'-人员入侵 '101504'-专网
 */
@observer
class TaskList extends React.Component {
  state = {
    queryType: 1, // 任务来源 详见说明
    taskStatuses: [1], // 任务状态
    loading: true,
    name: '', // 搜索框
    alarmIds: [], // 选中的布控任务id
    checkAll: false, //全选状态
    list: [], // 布控任务列表,
    confirmVisible: false // 布控告警弹窗状态
  }

  confirmOptions = {} // 确认弹窗参数

  componentDidMount() {
    const { queryType = 1 } = this.props
    this.setState({
      queryType
    })
    this.getTaskList({ queryType, name: '' }).then(result => {
      this.getAlarmUnResolveNum(result)
      this.getAlarmHistoryId(result, true)
    })
  }

  getAlarmUnResolveNum(list) {
    const ids = list.map(v => v.id)
    if (ids.length === 0) {
      return
    }
    const options = { taskIds: ids }
    if (this.props.taskType === '101503') {
      options.startTime = moment()
        .subtract(30, 'data')
        .set({ hour: 0, minute: 0, second: 0 })
        .valueOf()
      options.endTime = Date.now()
    }
    Service.monitorTask.countTaskUnhandledNum(options).then(res => {
      if (!res.data) {
        return
      }
      list.forEach(item => {
        if (res.data[item.id]) {
          item.unhandledAlarmCount = res.data[item.id]
        }
      })
      this.setState({ list })
    })
  }

  //初始化列表时，对列表进行处理，提取告警列表id，是否同步到父组件（isToFather, 默认不同步）
  getAlarmHistoryId = (data = [], isToFather) => {
    if (!data || !data.length) {
      this.setState({
        loading: false
      })
      // return false
    }
    let alarmIds = data.map(v => v.id)
    //布控告警所有id-全局设置
    this.alarmAllIds = alarmIds
    //初始全部选中
    this.setState({
      alarmIds,
      checkAll: true
    })
    // 数据传递到父组件
    if (!isToFather) {
      this.setState({
        loading: false
      })
      return false
    }
    const { onTaskChange } = this.props
    if (onTaskChange) {
      onTaskChange(alarmIds)
        .then(res => {
          this.setState({
            loading: false
          })
          if (!res) {
            return
          }
        })
        .catch(e => {
          this.setState({
            loading: false
          })
        })
    }
  }

  /**根据条件查询任务列表 */
  getTaskList = (option = {}) => {
    this.setState({ loading: true })
    let { queryType, name, taskStatuses } = this.state
    let { taskTypes } = this.props
    let data = Object.assign({}, { taskTypes, queryType, name, limit: 500, taskStatuses }, option)
    if (taskStatuses[0] === -1) {
      delete data.taskStatuses
    }
    const isAlarm = true
    return Service.monitorTask.queryMonitorTasks(data, isAlarm).then(res => {
      if (!res) {
        message.error('布控任务列表查询失败')
        this.setState({ loading: false })
        return false
      }
      let list = res.data && res.data.list
      this.setState({
        loading: false,
        list
      })
      return list
    })
  }

  //切换布控任务来源
  onOriginSearch = queryType => {
    this.setState(
      {
        queryType,
        loading: true
      },
      () => {
        this.handleInputSearch(this.state.name)
      }
    )
  }
  // 切换布控任务状态
  onStatusChange = taskStatus => {
    this.setState(
      {
        taskStatuses: [taskStatus]
      },
      () => {
        this.getTaskList().then(result => {
          this.getAlarmUnResolveNum(result)
          this.getAlarmHistoryId(result, true)
        })
      }
    )
  }
  /*设置忽略/取消忽略他人授权的布控任务报警 */
  setWhetherIgnoreAlarm = (e, data) => {
    Utils.stopPropagation(e)
    let confirmOptions = {
      taskId: data.id,
      ignore: data.ignoreStatus === 0 ? 1 : 0
    }
    this.confirmOptions = confirmOptions
    this.ignoreStatus = data.ignoreStatus === 0 ? 1 : 0
    this.setState({
      confirmVisible: true
    })
  }
  // 设置忽略/取消忽略他人授权的布控任务报警 --- 二次确认取消
  handleCancelIgnoreStatus = () => {
    this.setState({
      confirmVisible: false
    })
    this.confirmOptions = {}
  }
  // 设置忽略/取消忽略他人授权的布控任务报警 --- 二次确认确认修改
  handleOkIgnoreStatus = () => {
    Service.monitorTask.setWhetherIgnoreAlarm(this.confirmOptions).then(res => {
      if (res.code === 0) {
        message.success('设置成功')
        this.setState({
          confirmVisible: false
        })
        this.getTaskList()
      }
    })
  }

  /**布控名称搜索 */
  handleInputSearch = (name = '') => {
    this.setState({ name })
    this.getTaskList({ name }).then(result => {
      this.getAlarmUnResolveNum(result)
      this.getAlarmHistoryId(result, true)
    })
  }

  // 全选
  alarmCheckAll = e => {
    const { onTaskChange } = this.props
    let checkValue = e.target.checked
    this.setState({
      alarmIds: checkValue ? this.alarmAllIds : [],
      checkAll: checkValue
    })
    onTaskChange && onTaskChange(checkValue ? this.alarmAllIds : [])
  }
  // 布控任务选中
  changeAlarmTask = id => {
    const { onTaskChange } = this.props
    const alarmIds = [id]
    const checkAll = this.alarmAllIds.length === 1
    this.setState({
      alarmIds,
      checkAll
    })
    onTaskChange && onTaskChange(alarmIds)
  }
  // 告警数量格式化
  numberFormat(num) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }
  //判断布控任务状态
  taskTypeStr = item => {
    const statusData = statusMap.find(v => v.value === item.taskStatus)
    return statusData
  }

  // 渲染布控任务列表项
  renderItem = v => {
    const { alarmIds, checkAll } = this.state
    const { statusCls, statusLabel } = this.taskTypeStr(v)
    return (
      <List.Item key={v.id}>
        <div
          className={`item 
            ${alarmIds.find(item => item == v.id) && 'active'} 
            ${checkAll ? 'check-all' : ''}
          `}
          onClick={() => this.changeAlarmTask(v.id)}
        >
          <p className="title-name" title={v.name}>
            {v.name}
          </p>
          <div className="item-content">
            <div className="item-status">
              <span className={`status ${statusCls}`} />
              <span>{statusLabel}</span>
            </div>
            <div className="item-num">
              <span className="num">{(!!v.unhandledAlarmCount && this.numberFormat(+v.unhandledAlarmCount)) || 0}</span>
              {v.taskStatus === 1 && (
                <div className="num-box">
                  <span
                    className="icon-span"
                    title={`${v.ignoreStatus === 0 ? '关闭' : '开启'}实时提醒`}
                    onClick={e => this.setWhetherIgnoreAlarm(e, v)}
                  >
                    <IconFont type={v.ignoreStatus === 0 ? 'icon-S_Photo_AlarmOpen' : 'icon-S_Photo_AlarmClose'} />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </List.Item>
    )
  }

  render() {
    const { loading, list = [], confirmVisible, queryType, taskStatuses, checkAll } = this.state

    //取到所有的布控告警id
    this.alarmAllIds = list.map(v => v.id)

    return (
      <div className="alarm-list-wrapper">
        <div className="search-group">
          <SearchInput size={'default'} placeholder={'请输入任务名称搜索'} onChange={this.handleInputSearch} style={{ width: '100%' }} />
        </div>
        <TaskListFilter
          disabled={loading}
          originType={queryType}
          taskStatus={taskStatuses[0]}
          onOriginSearch={this.onOriginSearch}
          onStatusChange={this.onStatusChange}
        />
        <div className="list-total">
          <span className="list-total-text">我的任务列表：</span>
          {!!list.length && (
            <div className="list-total-checkbox">
              <span className="checkbox-span">全部显示</span>
              <Popover
                overlayClassName={'checkbox-span-pop'}
                placement="bottom"
                content={<div className="checkbox-span-pop-div">请选择下面列表查看单个任务告警</div>}
              >
                <Checkbox onChange={this.alarmCheckAll} checked={checkAll} />
              </Popover>
            </div>
          )}
        </div>
        <div className="list alarm-list">
          {loading ? (
            <Loading />
          ) : (
            <List locale={{ emptyText: <NoData title="告警" imgType={1} /> }} dataSource={list} renderItem={this.renderItem} />
          )}
        </div>
        <ConfirmComponent
          title={`${this.confirmOptions.ignore === 0 ? '开启' : '关闭'}实时提醒确认`}
          visible={confirmVisible}
          onCancel={this.handleCancelIgnoreStatus}
          onOk={this.handleOkIgnoreStatus}
          className="ignore-status-model"
          width={320}
          icon={this.confirmOptions.ignore === 1 ? 'icon-S_Photo_AlarmClose' : 'icon-S_Photo_AlarmOpen'}
          children={
            <div className="box-model">
              点击“确认”
              {`${this.confirmOptions.ignore === 0 ? '开启' : '关闭'}`}
              该任务的实时提醒
            </div>
          }
        />
      </div>
    )
  }
}

export default TaskList
