import React from "react";

const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');

const cards = [
  {
    type: "KeyPointCard",
    component: Loader.loadBusinessComponent("Card", "KeyPointCard")
  },
  {
    type: "ForeignCard",
    component: Loader.loadBusinessComponent("Card", "ForeignCard")
  }
];

class RealAlarmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleVisible: false,
      type: 1,
      modalItem: {},
      hasLoadMore: true
    };
    this.infinitRef = React.createRef();
  }

  handlePageJump = (id, libType) => {
    const { handlePageJump } = this.props;
    handlePageJump && handlePageJump(id, libType);
  };

  handleButtonClick = () => {
    const { tab, location, moduleName } = this.props;
    tab.goPage({ moduleName, location });
  };

  loadMore = () => {
      // message.warn("没有更多实时警情了！点击“查看更多”浏览历史警情。");
  };

  render() {
    let {
      list,
      libType,
      infinitKey,
      cardType,
      pdWidth,
      itemWidth,
      itemHeight
    } = this.props;
    let { hasLoadMore } = this.state;
    let Card = cards.filter(v => v.type === cardType)[0].component;
    return (
      <InfiniteScrollLayout
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          key={infinitKey}
          pdWidth={pdWidth}
          data={list}
          ref={this.infinitRef}
          hasBackTop={true}
          loadMore={this.loadMore}
          hasLoadMore={hasLoadMore}
          renderItem={(item, index) => (
            <div key={item.id}>
              <Card
                data={item}
                isActual={true}
                height={itemHeight}
                score={libType === 4 ? item.score : null}
                handleJumPage={this.handlePageJump}
              />
            </div>
          )}
        />
    );
  }
}

export default RealAlarmList;
